// TODO: Move this to the shared lib when we have one

import pino, { type Logger, type LoggerOptions } from "pino";

const logLevelData = {
  "*": "info",
  api: "debug",
};

interface LogLevel {
  severity: string;
  level: number;
}

const levelFormatter = (_label: string, level: number): LogLevel => {
  let severity;
  switch (level) {
    case 10:
    case 20: {
      severity = "DEBUG";
      break;
    }

    case 30: {
      severity = "INFO";
      break;
    }

    case 40: {
      severity = "WARNING";
      break;
    }

    case 50: {
      severity = "ERROR";
      break;
    }

    case 60: {
      severity = "CRITICAL";
      break;
    }

    default: {
      severity = "DEFAULT";
      break;
    }
  }

  return { severity, level };
};

const loggerOptions: LoggerOptions = {
  base: undefined,
  formatters: {
    level: levelFormatter,
  },
  messageKey: "message",
  redact: ["password", "body.password"],
};

const logLevels = new Map<string, string>(Object.entries(logLevelData));

export function getLogLevel(logger = "*"): string {
  return logLevels.get(logger) ?? "info";
}

export function getLogger(name?: string): Logger {
  const isServer = typeof window === "undefined";
  if (isServer) {
    return pino({ name, level: getLogLevel(name), ...loggerOptions });
  } else {
    return pino({
      name,
      level: getLogLevel(name),
      ...loggerOptions,
    });
  }
}
