import { FC, FormEvent, ReactNode, useMemo } from "react";
import classNames from "classnames";
import { snakeCase } from "lodash";

import { IconType } from "@lib/shared-types";

import { Button } from "@components/Button";
import CloseIcon from "@components/Icons/CloseIcon";
import Modal, { ModalProps } from "@components/Modals/Modal";
import SmallModalButton from "@components/Modals/SmallModalButton";

export interface SmallModalProps extends ModalProps {
  title?: ReactNode;
  description?: string | JSX.Element;
  icon?: IconType;
  isActionDisabled?: boolean;
  iconColor?: "action" | "green" | "blue" | "peach" | "grey";
  bigIcon?: boolean;
  onActionText?: string | JSX.Element;
  onAction?: (data: any, e?: FormEvent) => void | Promise<void>;
  onCancelText?: string;
  onActionLoading?: boolean;
  onSecondaryActionText?: string;
  onSecondaryAction?: (value: any) => void;
  onSecondaryActionLoading?: boolean;
  isCloseAfterSecondaryActionDisabled?: boolean;
  onTertiaryActionText?: string;
  onTertiaryAction?: (value: any) => void;
  isCloseAfterTertiaryActionDisabled?: boolean;
  isCloseAfterPrimaryActionDisabled?: boolean;
  heapModalName?: string;
  showCancel?: boolean;
  modalClassNames?: string;
}

const SmallModal: FC<SmallModalProps> = ({
  trigger,
  show,
  toggleShow,
  disableClose,
  icon: Icon,
  iconColor = "blue",
  bigIcon = false,
  title,
  description,
  onActionText,
  onAction,
  onActionLoading = false,
  isActionDisabled = false,
  onSecondaryActionText,
  onSecondaryAction,
  onSecondaryActionLoading = false,
  isCloseAfterSecondaryActionDisabled = false,
  isCloseAfterPrimaryActionDisabled = false,
  onTertiaryActionText,
  onTertiaryAction,
  heapModalName,
  children,
  showCancel = true,
  modalClassNames,
  onCancelText,
}) => {
  // in case heapModalName is not in the right format (do_something_modal)
  const modalName = useMemo(() => {
    let modalName = snakeCase(heapModalName);
    if (!modalName?.endsWith("_modal")) {
      modalName += "_modal";
    }
    return modalName;
  }, [heapModalName]);

  const handleAction = async (e) => {
    onAction && (await onAction(e));
    if (!isCloseAfterPrimaryActionDisabled) {
      handleClose();
    }
  };

  const handleClose = () => toggleShow && toggleShow(false);

  return (
    <Modal
      className={classNames("p-6 sm:m-auto max-h-full", modalClassNames)}
      trigger={trigger}
      show={show}
      toggleShow={toggleShow}
      disableClose={disableClose}
      size="small"
    >
      {!disableClose && (
        <div>
          <Button
            icon={<CloseIcon />}
            data-heap-event-name={`${modalName}_cancel_button_clicked`}
            circle
            square
            smaller
            onClick={handleClose}
          />
        </div>
      )}

      {Icon && (
        <div className="flex justify-center my-6 relative">
          {bigIcon ? (
            <Icon className="flex items-center justify-center h-16 w-16" />
          ) : (
            <div
              className={classNames(
                "rounded-full flex items-center justify-center h-16 w-16",
                {
                  action: "bg-action-950",
                  green: "bg-green-900",
                  blue: "bg-blue-950",
                  peach: "bg-peach-950",
                  grey: "bg-grey-950",
                }[iconColor]
              )}
            >
              <Icon
                className={classNames(
                  "h-8 w-8",
                  {
                    action: "text-action-500",
                    green: "text-green-300",
                    blue: "text-blue-500",
                    peach: "text-peach-500",
                    grey: "text-grey-500",
                  }[iconColor]
                )}
              />
            </div>
          )}
        </div>
      )}
      {title && (
        <h3 className="text-center text-2xl font-medium leading-7 mb-2 mt-6">
          {title}
        </h3>
      )}
      {description && (
        <p className="text-grey-500 text-center mb-6">{description}</p>
      )}
      {children}
      {onActionText && (
        <>
          <div
            className={classNames(
              !(onSecondaryActionText || onTertiaryActionText)
                ? "sm:hidden flex gap-2 mt-6"
                : "hidden"
            )}
          >
            <Button
              className="w-full"
              data-heap-event-name={`${modalName}_cancel_button_clicked`}
              onClick={handleClose}
            >
              {onCancelText || "Cancel"}
            </Button>
            <SmallModalButton
              iconColor={iconColor}
              onAction={handleAction}
              onActionText={onActionText}
              isActionDisabled={isActionDisabled}
              onActionLoading={onActionLoading}
              modalName={modalName}
              primary
            />
          </div>
          <div
            className={classNames(
              "space-y-2",
              !(onSecondaryActionText || onTertiaryActionText)
                ? "hidden sm:block mt-6"
                : "block"
            )}
          >
            <SmallModalButton
              iconColor={iconColor}
              onAction={handleAction}
              onActionText={onActionText}
              isActionDisabled={isActionDisabled}
              onActionLoading={onActionLoading}
              modalName={modalName}
              primary
            />
            {onSecondaryActionText && (
              <SmallModalButton
                iconColor={iconColor}
                onAction={(e) => {
                  onSecondaryAction && onSecondaryAction(e);
                  if (!isCloseAfterSecondaryActionDisabled) {
                    handleClose();
                  }
                }}
                onActionText={onSecondaryActionText}
                onActionLoading={onSecondaryActionLoading}
                modalName={modalName}
              />
            )}
            {onTertiaryActionText && (
              <SmallModalButton
                iconColor={iconColor}
                onAction={(e) => {
                  onTertiaryAction && onTertiaryAction(e);
                  handleClose();
                }}
                onActionText={onTertiaryActionText}
                modalName={modalName}
              />
            )}
            {showCancel && (
              <Button
                className="w-full"
                data-heap-event-name={`${modalName}_cancel_button_clicked`}
                onClick={handleClose}
              >
                {onCancelText || "Cancel"}
              </Button>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default SmallModal;
